import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { ParallaxProvider } from 'react-scroll-parallax/cjs';
import { boundClass } from 'autobind-decorator';

@boundClass
class Layout extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        return (
            <>
                <ParallaxProvider>
                    <Header/>
                    <main>{this.props.children}</main>
                    <Footer cursorType="pointer-secondary"/>
                </ParallaxProvider>
            </>
        );
    }

}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
