import React, { Component } from 'react';
import Button from '../Nav/Button';
import logo from './assets/logo/webiya.svg';
import closeImg from './assets/close.svg';
import menuImg from './assets/menu.svg';
import Nav from '../Nav/Nav';
import * as style from './header.module.scss';
import AnchorLink from '../AnchorLink/AnchorLink';
import Img from '../Img/Img';
import { boundClass } from 'autobind-decorator';
import cx from 'classnames';

@boundClass
class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
    }

    handleToggle(isOpen) {
        this.setState({ isOpen: isOpen });
    }


    render() {
        return (
            <header>
                <div
                    className={cx(style.opacityBackground, { [style.active]: this.state.isOpen })}
                    onClick={() => this.handleToggle(false)}
                >
                </div>
                <h1 className={style.logoLink} aria-label="Webiya logo">
                    <Button tabIndex="1" to="/">
                        <img src={logo} alt="webiya" loading="lazy"/>
                    </Button>
                </h1>
                <button
                    className={style.menuButton}
                    onClick={() => this.handleToggle(true)}
                    type="button"
                    data-toggle="collapse"
                    aria-controls="navbarButtons"
                    aria-expanded={this.state.isOpen}
                    aria-label="Toggle navigation"
                >
                    <Img src={menuImg} alt="menu button icon"/>
                </button>
                <nav aria-label="navigation section">
                    <div
                        className= {cx(style.buttonsNavWrapper, { [style.showMenu]: this.state.isOpen })}
                        id="navbarButtons"
                    >
                        <button
                            className={style.closeMenuButton}
                            onClick={() => this.handleToggle(false)}
                            type="button"
                            aria-label="Close"
                        >
                            <Img src={closeImg} alt="close button icon"/>
                        </button>
                        <Nav cursorType="pointer" isOpen={() => this.handleToggle(false)}/>
                        <AnchorLink
                            isOpen={() => this.handleToggle(false)}
                            cursorType="pointer"
                            id="contact"
                            color="primary"
                            tabIndex="1"
                        >
                            contact us
                        </AnchorLink>
                    </div>
                </nav>
            </header>
        );
    }
}


export default Header;
