import PropTypes from 'prop-types';
import React from 'react';
import * as style from './button.module.scss';
import { Link } from 'gatsby';
import cx from 'classnames';
import withCursor from '../../HOCs/withCursor';

const Button = props => {

    const {
        tag,
        href,
        to,
        color,
        className,
        children,
        context,
        cursorType,
        ...restProps
    } = props;

    const Tag = tag || (href ? 'a' : to ? Link : 'button');


    const classNames = cx(
        style[color],
        className
    );

    const nextProps = {
        ...restProps,
        href,
        to,
        className: classNames
    };
    const { onCursor } = context;
    return <Tag
        {...nextProps}
        onMouseEnter={() => onCursor(cursorType)}
        onMouseLeave={onCursor}>
        {children}
    </Tag>;
};

Button.propTypes = {
    to: PropTypes.string,
    href: PropTypes.string,
    color: PropTypes.oneOf(['primary', 'secondary', 'link']),
    outline: PropTypes.bool,
    children: PropTypes.node,
    cursorType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

export default withCursor(Button);
