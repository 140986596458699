import React, { Component } from 'react';
import * as style from './customCorser.module.scss';
import { boundClass } from 'autobind-decorator';
import cx from 'classnames';
import PropTypes from 'prop-types';
@boundClass
class CustomCursor extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {
            topPosition,
            leftPosition,
            className
        } = this.props;
        return (
            <div className={style.cursorContainer}
                 style={{
                     transform: `translateX(${leftPosition}px) translateY(${topPosition}px)`
                 }}
            >
                <div className={cx(style.innerCircle, className)} />
                <div className={cx(style.bigCircle, className)} />
            </div>
        );
    }
}

CustomCursor.propTypes = {
    top: PropTypes.number,
    left: PropTypes.number,
    className: PropTypes.string
};

export default CustomCursor;