import React from 'react';
import cx from 'classnames';
import * as style from './container.module.scss';
import PropTypes from 'prop-types';
const Container = (props) => {

    const {
        children,
        className,
        color,
        id,
        ariaLabel
    } = props;

    return(
        <section
            id={id}
            className={cx(style.container, style[color], className)}
            aria-label={ariaLabel}
        >
            {children}
        </section>
    );
};

Container.propTypes = {
    color: PropTypes.oneOf(['red', 'white', 'gradient']),
    className: PropTypes.string,
    id: PropTypes.string,
    children: PropTypes.node.isRequired
};
export default Container;
