import React from 'react';
import PropTypes from 'prop-types';

const Img = ({ src, alt, className, tag }) => {
  const Tag = tag || 'img';
  const nextProps = {
    src,
    alt,
    className
  };
  
  return <Tag {...nextProps} loading="lazy"/>;
};



Img.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default Img;