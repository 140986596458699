import React from 'react';
import Nav from '../Nav/Nav';
import * as style from './footer.module.scss';
import withCursor from '../../HOCs/withCursor';
import Container from '../Container/Container';
const Footer = (props) => {

  const {
    context,
      cursorType
  } = props;

  return (
    <footer
        onMouseEnter={() => context.onCursor(cursorType)}
        onMouseLeave={context.onCursor}
    >
      <Container color="red" ariaLabel="Footer section" className={style.container}>
        <section className={style.contentContainer}>
          <div className={style.itemsContent} aria-label="Navigation buttons section">
            <Nav cursorType="pointer-secondary" className={style.secondaryAnchorColor}/>
          </div>
        </section>
      </Container>
    </footer>
  );
};

export default withCursor(Footer);
