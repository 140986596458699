import React, { Component } from 'react';
import Button from '../Nav/Button';
import { Link } from 'react-scroll';
import PropTypes from 'prop-types';
import withCursor from '../../HOCs/withCursor';
import { isMobile } from 'react-device-detect';
import { boundClass } from 'autobind-decorator';

@boundClass
class AnchorLink extends Component {

    constructor(props) {
        super(props);
    }

    handleChanges(e) {
        if (isMobile && this.props.isOpen)
            this.props.isOpen(false);
        const current =  e.currentTarget;
        return setTimeout(() => {
            current.blur();
        }, 1000);
    }

    render() {
        const {
            id,
            color,
            className,
            context,
            cursorType,
            tabIndex,
            children
        } = this.props;

        return (
            <Button href={`#${id}`}
                    tag={Link}
                    color={color}
                    to={id}
                    className={ className }
                    spy={true}
                    offset={isMobile ? -100 : -400}
                    smooth={true}
                    duration={1000}
                    onClick={this.handleChanges}
                    context={context}
                    cursorType={cursorType}
                    tabIndex={tabIndex}
            >
                {children}
            </Button>
        );
    }
}

AnchorLink.propTypes = {
    to: PropTypes.string,
    href: PropTypes.string,
    color: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    cursorType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])

};

export default withCursor(AnchorLink);