import React from 'react';
import propTypes from 'prop-types';
import cx from 'classnames';
import * as style from './nav.module.scss';
import AnchorLink from '../AnchorLink/AnchorLink';

const buttonsDataList = [
    {
        id: 'home',
        content: 'home',
    },
    {
        id: 'service',
        content: 'services',
    },
    {
        id: 'whyUs',
        content: 'why us',
    },
    {
        id: 'tech',
        content: 'tech',
    },
    {
        id: 'customers',
        content: 'Clients',
    },
];

const Nav = props => {
    const {
        className,
        cursorType
    } = props;
    return (
        <ul className={cx(style.buttonsList, style.mobile)}>
            {buttonsDataList.map(buttonData => (
                <li
                    className={cx(style.buttonItem, style.mobile)}
                    key={buttonData.content}
                >
                    <AnchorLink id={buttonData.id}
                                color="link"
                                className={cx(className,style.menuItem)}
                                isOpen={props.isOpen}
                                cursorType = {cursorType}
                                tabIndex="1"

                    >
                        {buttonData.content}
                    </AnchorLink>
                </li>
            ))}
        </ul>
    );
};

Nav.propTypes = {
    className: propTypes.string,
};

export default Nav;
